exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-author-post-js": () => import("./../../../src/templates/author-post.js" /* webpackChunkName: "component---src-templates-author-post-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-category-post-js": () => import("./../../../src/templates/category-post.js" /* webpackChunkName: "component---src-templates-category-post-js" */),
  "component---src-templates-configuration-js": () => import("./../../../src/templates/configuration.js" /* webpackChunkName: "component---src-templates-configuration-js" */),
  "component---src-templates-full-width-page-js": () => import("./../../../src/templates/full-width-page.js" /* webpackChunkName: "component---src-templates-full-width-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-most-popular-blog-page-js": () => import("./../../../src/templates/most-popular-blog-page.js" /* webpackChunkName: "component---src-templates-most-popular-blog-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policy-page.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-site-map-page-js": () => import("./../../../src/templates/site-map-page.js" /* webpackChunkName: "component---src-templates-site-map-page-js" */),
  "component---src-templates-tags-page-js": () => import("./../../../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-tags-post-js": () => import("./../../../src/templates/tags-post.js" /* webpackChunkName: "component---src-templates-tags-post-js" */)
}

